import styled from 'styled-components';

export const Input = styled.input`
  font-family: "Proxima Nova", sans-serif;
  font-size: 40px;
  width: 60%;
  margin: 10px auto 0 auto;
  text-align: center;
  border: none;
  border-bottom: 2px solid #10A8E5;
`;
