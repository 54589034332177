import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';

import './index.css';
import App from './components/App/App.jsx';
import * as serviceWorker from './serviceWorker';

// Stores
import NumberStore from './stores/NumberStore';

ReactDOM.render(
	<Provider NumberStore={NumberStore}>
		<App/>
	</Provider>,
	document.getElementById('root')
);

serviceWorker.register();
