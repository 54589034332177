import React from 'react';

// Styled
import { Container } from "./styles/Container";
import { Button } from "./styles/Button";

const GenerateButton = (props) => {
	return (
		<Container>
			<Button onClick={props.onClick}>
				Generate
			</Button>
		</Container>
	);
};

export default GenerateButton;
