import styled from 'styled-components';

export const SubmitButton = styled.button`
  font-family: "Proxima Nova", sans-serif;
  font-size: 20px;
  margin: 30px 0 0 0;
  padding: 10px 20px;
  color: #ffffff;
  background: #10A8E5;
  border-radius: 34px;
  border: none;
  box-shadow: rgba(76, 80, 95, 0.21) 0 4px 5px;
`;
