import styled from 'styled-components';

export const HistoryItem = styled.div`
  font-family: "Proxima Nova", sans-serif;
  font-size: 30px;
  font-weight: bold;
  justify-self: center;
  color: #4C505F;
  z-index: -1;
`;
