import React from 'react';

// Styled
import { Container } from './styles/Container';
import { Modal } from './styles/Modal';
import { ExitButton } from './styles/ExitButton';
import { Title } from './styles/Title';
import { Input } from './styles/Input';
import { SubmitButton } from './styles/SubmitButton';

const ChangeMinMaxModal = (props) => {
	return (
		<Container>
			<Modal>
				<ExitButton onClick={props.exit}>
					+
				</ExitButton>
				<Title>
					{props.minMax}
				</Title>
				<Input type="number" placeholder={props.current} onChange={props.onChangeMinMaxValue}/>
				<br/>
				<SubmitButton onClick={props.submit}>
					Submit
				</SubmitButton>
			</Modal>
		</Container>
	);
};

export default ChangeMinMaxModal;
