import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

// Components
import RandomNumber from '../RandomNumber/RandomNumber';
import MinMax from '../MinMax/MinMax';
import GenerateButton from '../GenerateButton/GenerateButton';
import History from '../History/History';
import ChangeMinMaxModal from "../ChangeMinMaxModal/ChangeMinMaxModal";

// Styled
import { Title } from '../common/Title';
import { Footer } from '../common/Footer';

@inject('NumberStore')
@observer
class App extends Component {

	constructor(props) {
		super(props);

		this.state = {
			minMaxValue: 0,
			current: 0,
			editing: false,
			minMax: ''
		};

		this.handleClick = this.handleClick.bind(this);
	}

	/**
	 * Handle the click event of the generate button
	 */
	handleClick = () => {
		this.props.NumberStore.generateRandomNumber(
			this.props.NumberStore.min,
			this.props.NumberStore.max
		)
	};

	/**
	 * Get the last number out of the history array
	 * @returns {any}
	 */
	getLastNumber = () => {
		return this.props.NumberStore.NumberHistoryBackground[this.props.NumberStore.NumberHistoryBackground.length - 2];
	};

	/**
	 * Exit out of the Modal
	 */
	exit = () => {
		this.setState(prevState => {
			return {
				minMax: '',
				editing: false
			}
		});
	};

	/**
	 * Edit the Min or Max Value
	 * @param e
	 */
	editMinMax = (e) => {
		const minMax = e.target.getAttribute('name');
		let current = 0;

		if (minMax === 'min') {
			current = this.props.NumberStore.min;
		} else {
			current = this.props.NumberStore.max;
		}

		this.setState(prevState => {
			return {
				current: current,
				minMax: minMax,
				editing: !prevState.editing
			}
		});
	};

	/**
	 * Change the value of Min or Max
	 */
	onChangeMinMaxValue = (e) => {
		const value = e.target.value;
		this.setState(prevstate => {
			return {
				minMaxValue: value
			}
		})
	};

	/**
	 * Submit the change to Min or Max
	 */
	submitMinMaxChange = () => {
		if (this.state.minMax === "min") {
			if (this.state.minMaxValue < -10000 || this.state.minMaxValue > 10000) {
				this.props.NumberStore.updateMinMax("min", -10000);
			} else {
				this.props.NumberStore.updateMinMax("min", parseInt(this.state.minMaxValue));
			}
		} else {
			if (this.state.minMaxValue > 10000 || this.state.minMaxValue < -10000) {
				this.props.NumberStore.updateMinMax("max", 10000);
			} else {
				this.props.NumberStore.updateMinMax("max", parseInt(this.state.minMaxValue));
			}
		}
		this.setState({
			editing: false,
			minMax: '',
			minMaxValue: 0
		})
	};

	render() {
		return (
			<div>
				<Title>
					Random Number Generator
				</Title>
				<RandomNumber last={this.getLastNumber() || 1} number={this.props.NumberStore.randomNumber}/>
				<MinMax
					min={this.props.NumberStore.min}
					max={this.props.NumberStore.max}
					editMinMax={this.editMinMax}
				/>
				<GenerateButton
					onClick={this.handleClick}
				/>
				<History items={this.props.NumberStore.NumberHistory.toJS().reverse()}/>
				{this.state.editing &&
				<ChangeMinMaxModal
					current={this.state.current}
					minMax={this.state.minMax}
					exit={this.exit}
					onChangeMinMaxValue={this.onChangeMinMaxValue}
					submit={this.submitMinMaxChange}
				/>
				}
				<Footer>
					&copy; Ivo van der Knaap
				</Footer>
			</div>
		);
	}
}

export default App;
