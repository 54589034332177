import styled from 'styled-components';

export const Modal = styled.div`
  background: #ffffff;
  position: fixed;
  width: 80%;
  height: 250px;
  top: 30vh;
  left: 10%;
  text-align: center;
  border-radius: 34px;
`;
