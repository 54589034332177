import React from 'react';

// Styled
import { Container } from './styles/Container';
import { NumberContainer } from './styles/NumberContainer';
import { Backdrop } from './styles/Backdrop';
import { Number } from './styles/Number';

const RandomNumber = (props) => {
	return (
		<Container>
			<NumberContainer name='min' onClick={props.editMinMax}>
				<Backdrop name='min'>
					Min
				</Backdrop>
				<Number name='min'>
					{props.min}
				</Number>
			</NumberContainer>
			<NumberContainer name='max' onClick={props.editMinMax}>
				<Backdrop name='max'>
					Max
				</Backdrop>
				<Number name='max'>
					{props.max}
				</Number>
			</NumberContainer>
		</Container>
	)
};

export default RandomNumber;
