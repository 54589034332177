import styled from 'styled-components';

export const ExitButton = styled.div`
  font-family: "Proxima Nova", sans-serif;
  font-size: 45px;
  right: 25px;
  position: absolute;
  width: 5%;
  transform: rotate(45deg);
`;
