import React from 'react';
import CountUp from 'react-countup';

// Styled
import { Container } from './styles/Container';
	import { Backdrop } from './styles/Backdrop';
	import { Number } from './styles/Number';

	const RandomNumber = (props) => {
	return (
		<Container>
			<Backdrop>
				Random
			</Backdrop>
			<Number>
				<CountUp start={props.last} end={props.number}/>
			</Number>
		</Container>
	)
};

export default RandomNumber;
