import styled from 'styled-components';

export const Footer = styled.h1`
  font-family: "Proxima Nova", sans-serif;
  font-size: 20px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  color: #4C505F;
  background: #ffffff;
`;
