import React from 'react';

// Styled
import { Container } from './styles/Container';
import { TitleContainer } from './styles/TitleContainer';
import { Title } from './styles/Title';
import { HistoryItems } from './styles/HistoryItems';
import { HistoryItem } from './styles/HistoryItem';

const History = (props) => {
	return (
		<Container>
			<TitleContainer>
				<Title>
					History
				</Title>
			</TitleContainer>
			<HistoryItems>
				{props.items.map((item, index) => {
					return <HistoryItem key={index}>{item}</HistoryItem>
				})}
			</HistoryItems>
		</Container>
	);
};

export default History;
