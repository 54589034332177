import {action, observable} from 'mobx'

let store;

class NumberStore {
  @observable min = 0;
  @observable max = 100;
  @observable randomNumber = 1;
  @observable NumberHistory = [];
  @observable NumberHistoryBackground = [];

  @action
  generateRandomNumber(min, max) {
    const constrainedMax = max - min;
    const randomNumber = Math.floor(Math.random() * constrainedMax) + min;
    this.randomNumber = randomNumber;
    this.NumberHistoryBackground.push(randomNumber);

    setTimeout(() => {
      this.NumberHistory.push(randomNumber);
    }, 1500);

    console.log(this.NumberHistoryBackground.toJS());
  }

  @action
  updateMinMax(minOrMax, value) {
    if(minOrMax === "min") {
      this.min = value;
    } else {
      this.max = value;
    }
  }

}

store = new NumberStore();
export default store;
